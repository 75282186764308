import React from "react"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <footer>
        <span>Footer</span>
        <Link to="/impressum/">Impressum</Link>
        <Link to="/datenschutz/">Datenschutz</Link>
    </footer>
  )
}