import Typography from "typography"
import sutroTheme from "typography-theme-sutro"

sutroTheme.headerFontFamily = ['Lato', 'Arial', 'sans-serif'];
sutroTheme.bodyFontFamily = ['Playfair Display', 'serif'];
sutroTheme.googleFonts = [
    {
        name: 'Lato',
        styles: [
        '400',
        '700'
        ]
    },
    {
        name: 'Playfair Display',
        styles: [
        '400',
        '500'
        ]
    }
];

const typography = new Typography(sutroTheme)

export const { scale, rhythm, options } = typography

export default typography


