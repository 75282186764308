import React from "react"
import Header from "./header"
import Footer from "./footer"

export default function Layout({ children }) {
  return (
    <div>
        <Header />
        <div className="container">
            {children}
        </div>
        <Footer />
    </div>
  )
}