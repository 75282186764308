import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Facebook, Phone, Whatsapp, Instagram } from "react-bootstrap-icons"
import * as headerStyles from "./header.module.scss";
import typography from "../utils/typography";

const socialListLink = props => (
    <li style={{color: '#fff'}}>
        <Link to={props.to} style={{
            fontFamily: typography.options.headerFontFamily.join(`,`),
            color: '#fff'
        }}>{props.children}</Link>
    </li>
)

export default function Header() {
  return (
    <header>
        <div className={headerStyles.topLine}>
            <div className="container">
                <ul>
                    <socialListLink to="https://google.de"><Phone /> +0440393</socialListLink>
                    <socialListLink to="https://google.de"><Facebook /></socialListLink>
                    <socialListLink to="https://google.de"><Whatsapp /></socialListLink>
                    <socialListLink to="https://google.de"><Instagram /></socialListLink>
                </ul>
            </div>
        </div>
        <div className={headerStyles.logo}>
            <div className="container">
                <Link to="/"><StaticImage src="../images/logo.png" width={200} alt="Test"/></Link>
            </div>
        </div>
        <div className={headerStyles.navigation}>
            <div className="container">
                <ul>
                    <li>Philosophie</li>
                    <li>Unser Team</li>
                    <li>Leistungen</li>
                    <li>Kontakt</li>
                </ul>
            </div>
        </div>
    </header>
  )
}